import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import TypeInNoSubmit from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P34-E1',
    audio: 'img/FriendsPlus/Page33/Audio/audio.e1.mp3',
    video: '',
    component: TypeInNoSubmit,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page33/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page33/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/4.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page33/Audio/9.mp3' },
        { url: 'img/FriendsPlus/Page33/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page33/Audio/10.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P34-E2',
    audio: 'img/FriendsPlus/Page33/Audio/sing.e2.mp3',
    video: '',
    component: TypeInNoSubmit,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page33/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page33/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page33/Audio/9.mp3' },
        { url: 'img/FriendsPlus/Page33/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page33/Audio/10.mp3' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P34-E3',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    maxLength: 2,
    fontSize: 50,
    inputHeight: '100%',
    //isAllowSubmit: false,
    // isLargeInput: true,
    exerciseKey: 'img/FriendsPlus/Page33/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/3.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page33/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/5.jpg', input: true, answer: "9" },
        { url: 'img/FriendsPlus/Page33/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/7.jpg', input: true, answer: "10" },
        { url: 'img/FriendsPlus/Page33/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/9.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page33/E3/10.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P34-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page33/E4/1.jpg' },
      ],
    ]
  },

}

export default json;