import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';



const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P44-E1',
    audio: 'img/FriendsPlus/Page44/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page44/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page44/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/I.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/J.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/K.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/L.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/M.mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page44/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/ink.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/jelly.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/kite.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/lion.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/mom.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P44-E2',
    audio: 'img/FriendsPlus/Page44/Audio/audio.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page44/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page44/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page44/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P44-E3',
    audio: 'img/FriendsPlus/Page44/Audio/audio.e3.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page44/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page44/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/tieude.e3.mp3' },
        { url: 'img/FriendsPlus/Page44/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page44/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/K.e3.mp3' },
        { url: 'img/FriendsPlus/Page44/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/L.e3.mp3' },
        { url: 'img/FriendsPlus/Page44/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/M.e3.mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page44/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/yak.mp3' },
        { url: 'img/FriendsPlus/Page44/E3/8.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/ill.mp3' },
        { url: 'img/FriendsPlus/Page44/E3/9.jpg', audioUrl: 'img/FriendsPlus/Page44/Audio/mom.mp3' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P44-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 8,
    exerciseKey: 'img/FriendsPlus/Page44/E4/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page44/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page44/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page44/E4/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page44/E4/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page44/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/7.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page44/E4/8.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page44/E4/9.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/11.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page44/E4/12.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page44/E4/13.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page44/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/15.jpg', input: 10, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/16.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page44/E4/17.jpg', input: 12, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/18.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page44/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/20.jpg', input: 13 },
        { url: 'img/FriendsPlus/Page44/E4/21.jpg', input: 14 },
        { url: 'img/FriendsPlus/Page44/E4/22.jpg', input: 15 },
        { url: 'img/FriendsPlus/Page44/E4/23.jpg', input: 16 },
        { url: 'img/FriendsPlus/Page44/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/25.jpg', input: 17, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/26.jpg', input: 18 },
        { url: 'img/FriendsPlus/Page44/E4/27.jpg', input: 19 },
        { url: 'img/FriendsPlus/Page44/E4/28.jpg', input: 20 },
        { url: 'img/FriendsPlus/Page44/E4/29.jpg', input: 21 },
        { url: 'img/FriendsPlus/Page44/E4/30.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page44/E4/31.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/32.jpg', input: 22 },
        { url: 'img/FriendsPlus/Page44/E4/33.jpg', input: 23 },
        { url: 'img/FriendsPlus/Page44/E4/34.jpg', input: 24 },
        { url: 'img/FriendsPlus/Page44/E4/35.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/36.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page44/E4/37.jpg', input: 26 },
        { url: 'img/FriendsPlus/Page44/E4/38.jpg', input: 27, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/39.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/40.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page44/E4/41.jpg', input: 29 },
        { url: 'img/FriendsPlus/Page44/E4/42.jpg', input: 30 },
        { url: 'img/FriendsPlus/Page44/E4/43.jpg', input: 31, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/44.jpg', input: 32 },
        { url: 'img/FriendsPlus/Page44/E4/45.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/46.jpg', input: 33 },
        { url: 'img/FriendsPlus/Page44/E4/47.jpg', input: 34, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/48.jpg', input: 35, isCorrect: true },
        { url: 'img/FriendsPlus/Page44/E4/49.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page44/E4/50.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P44-E5',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page44/E5/1.jpg' },
      ],
    ]
  },
}

export default json;
