import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
    1: { // Exercise num
        unit: 'Unit 1',
        id: 'SB1-U1-P15-E1',
        audio: 'img/FriendsPlus/Page15/Audio/audio.e12.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page15/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page15/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/tieude.e1.mp3' },
                { url: 'img/FriendsPlus/Page15/E1/3.jpg', videoUrl: 'Videos/P15E1.mp4' },
                { url: 'img/FriendsPlus/Page15/E1/4.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page15/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh1.e2.mp3' },
                { url: 'img/FriendsPlus/Page15/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh2.e2.mp3' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page15/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh3.e2.mp3' },
                { url: 'img/FriendsPlus/Page15/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh4.e2.mp3' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Unit 1',
        id: 'SB1-U1-P15-E2',
        audio: 'img/FriendsPlus/Page15/Audio/audio.e12.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page15/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page15/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/tieude.e2.mp3' },
                { url: 'img/FriendsPlus/Page15/E2/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page15/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh1.e2.mp3' },
                { url: 'img/FriendsPlus/Page15/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh2.e2.mp3' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page15/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh3.e2.mp3' },
                { url: 'img/FriendsPlus/Page15/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/anh4.e2.mp3' },
            ],

        ],
    },


}

export default json;