import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';



const json = {

  1: { // Exercise num
    unit: 'Culture',
    id: 'SB1-C-P48-E1',
    audio: 'img/FriendsPlus/Page50/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page50/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page50/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page50/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/VietNam.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Canada.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page50/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page50/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/australia.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page50/E1/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page50/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/16.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Japan.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page50/E1/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page50/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/20.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/the U.k..mp3' },
        { url: 'img/FriendsPlus/Page50/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/22.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Cambodia.mp3' },
        { url: 'img/FriendsPlus/Page50/E1/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page50/E1/24.jpg' },
      ],


    ],
  },


}
export default json;
