import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P31-E1',
    audio: 'img/FriendsPlus/Page31/Audio/audio vs anh e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page31/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page31/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page31/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page31/Audio/audio vs anh e1.mp3' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P31-E2',
    audio: 'img/FriendsPlus/Page31/Audio/sing.e2.mp3',
    video: '',
    songLyric:
      "<p>What are they? Roar, roar, roar.</p>" +
      "<p>They’re tigers. Roar, roar, roar.</p>" +
      "<p>They’re tigers. Roar, roar, roar.</p>" +
      "<p>What are they? Tweet, tweet, tweet.</p>" +
      "<p>They’re birds. Tweet, tweet, tweet.</p>" +
      "<p>They’re birds. Tweet, tweet, tweet.</p>" +
      "<p>What are they? Snap, snap, snap.</p>" +
      "<p>They’re hippos. Snap, snap, snap.</p>" +
      "<p> They’re hippos. Snap, snap, snap.</p>" +
      "<p> What are they? Grrr, grrr, grrr.</p>" +
      "<p>They’re bears. Grrr, grrr, grrr.</p>" +
      "<p> They’re bears. Grrr, grrr, grrr.</p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page31/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page31/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page31/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P31-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page31/E3/1.jpg' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P31-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page31/E4/1.jpg' },
      ],
    ]
  },

}

export default json;