import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P23-E1',
    audio: 'img/FriendsPlus/Page23/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page23/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page23/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/4.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P23-E2',
    audio: 'img/FriendsPlus/Page23/Audio/sing.e2.mp3',
    video: '',
    songLyric:
      "<p>Is it a plane? Is it a plane?</p>" +
      "<p>Yes, it is. It’s a plane.</p>" +
      "<p>Whoosh, whoosh, whoosh.</p>" +
      "<p>Whoosh, whoosh, whoosh.</p>" +
      "<p>Is it a robot? Is it a robot?</p>" +
      "<p>No, it isn’t. It’s a car.</p>" +
      "<p>Vroom, vroom, vroom.</p>" +
      "<p>Vroom, vroom, vroom.</p>" +
      "<p>Is it a puppet? Is it a puppet?</p>" +
      "<p>No, it isn’t. It’s a teddy.</p>" +
      "<p>Hug, hug, hug.</p>" +
      "<p>Hug, hug, hug.</p>" +
      "<p>Is it a balloon? Is it a balloon?</p>" +
      "<p>Yes, it is. It’s a balloon.</p>" +
      "<p>Pop, pop, pop.</p>" +
      "<p>Pop, pop, pop.</p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page23/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page23/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P23-E3',
    // audio: 'img/FriendsPlus/Page23/E3/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT2,
    exerciseKey: 'img/FriendsPlus/Page23/E3/Key/answerKey.png',
    recorder: true,
    toAnchor: '50% -1%',
    fromAnchor: '50% 100%',
    // titleImage: 'img/FriendsPlus/Page23/E3/1.jpg',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page23/E3/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page23/E3/3.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page23/E3/4.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page23/E3/5.jpg', isMatching: true, id: 4 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page23/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page23/E3/10.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page23/E3/11.jpg', isMatching: true, id: 6 },
        { url: 'img/FriendsPlus/Page23/E3/12.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page23/E3/13.jpg', isMatching: true, id: 8 },
      ],

    ],
    answers: ['1-6', '2-8', '3-5', '4-7'],
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P23-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page23/E4/1.jpg' },
      ],
    ]
  },


}

export default json;