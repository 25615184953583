import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import TypeInNoSubmit from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';




const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P39-E1',
    audio: 'img/FriendsPlus/Page39/Audio/audio.e1.mp3',
    video: '',
    component: TypeInNoSubmit,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/1.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/2.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/3.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/4.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page39/Audio/5.mp3' },
      ],

    ],
  },

  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P39-E2',
    // audio: 'img/FriendsPlus/Page39/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT2,
    exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    recorder: true,
    toAnchor: '50% -1%',
    fromAnchor: '50% 100%',
    // titleImage: 'img/FriendsPlus/Page39/E2/1.jpg',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E2/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page39/E2/3.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page39/E2/4.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page39/E2/5.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page39/E2/6.jpg', isMatching: true, id: 5 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page39/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/11.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page39/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page39/E2/12.jpg', isMatching: true, id: 11 },
        { url: 'img/FriendsPlus/Page39/E2/13.jpg', isMatching: true, id: 12 },
        { url: 'img/FriendsPlus/Page39/E2/14.jpg', isMatching: true, id: 13 },
        { url: 'img/FriendsPlus/Page39/E2/15.jpg', isMatching: true, id: 14 },
        { url: 'img/FriendsPlus/Page39/E2/16.jpg', isMatching: true, id: 15 },
      ],


    ],
    answers: ['1-15', '2-11', '3-13', '4-12', '5-14'],
  },


  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P39-E3',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 40,
    inputHeight: '100%',
    //isAllowSubmit: false,
    isLargeInput: true,
    exerciseKey: 'img/FriendsPlus/Page39/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E3/3.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page39/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page39/E3/5.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page39/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page39/E3/7.jpg', input: true, answer: "1" },
        { url: 'img/FriendsPlus/Page39/E3/8.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P39-E4',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 40,
    inputHeight: '100%',
    //isAllowSubmit: false,
    isLargeInput: true,
    exerciseKey: 'img/FriendsPlus/Page39/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/3.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page39/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/5.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page39/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/7.jpg', input: true, answer: "1" },
        { url: 'img/FriendsPlus/Page39/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/9.jpg', input: true, answer: "4" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page39/E4/10.jpg' },
      ],

    ],
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P39-E5',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page39/E5/1.jpg' },
      ],
    ]
  },



}

export default json;
