import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P5-E1',
    audio: 'img/FriendsPlus/Page5/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page5/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page5/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/4.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P5-E2',
    audio: 'img/FriendsPlus/Page5/Audio/sing.e2.mp3',
    video: '',
    songLyric:
      "<p> Hello, hello, what’s your name?<p>" +
      "<p> I’m Rosy. I’m Rosy.<p>" +
      "<p> Hello, hello.<p>" +
      "<p> Hello, hello, what’s your name?<p>" +
      "<p> I’m Tim.I’m Tim.<p>" +
      "<p> Hello, hello.<p>" +
      "<p> Hello, hello, what’s your name?<p>" +
      "<p> Gaga, gaga.<p>" +
      "<p> Hello, hello.<p>" +
      "<p> Goodbye, goodbye.<p>" +
      "<p> Goodbye to you, Rosy, Tim, and Billy.<p>" +
      "<p> Goodbye, goodbye. </p>",
    // exerciseKey: '',
    component: UnderLine,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page5/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page5/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P5-E3',
    audio: 'img/FriendsPlus/Page5/Audio/audio.e3.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/tieude.e3.mp3' },
        { url: 'img/FriendsPlus/Page5/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/anh1.e3.mp3' },
        { url: 'img/FriendsPlus/Page5/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/anh2.e3.mp3' },
        { url: 'img/FriendsPlus/Page5/E3/6_1.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/anh3.e3.mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/anh4.e3.mp3' },
        { url: 'img/FriendsPlus/Page5/E3/8_1.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/anh5.e3.mp3' },
        { url: 'img/FriendsPlus/Page5/E3/9_1.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P5-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page5/E4/1.jpg' },
      ],
    ]
  },

}

export default json;