import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB1-U1-P11-E1',
    audio: 'img/FriendsPlus/Page11/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page11/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page11/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page11/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E2/4.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB1-U1-P11-E2',
    audio: 'img/FriendsPlus/Page11/Audio/sing.e2.mp3',
    video: '',
    songLyric:
      "<p>It’s red.<p>" +
      "<p>It’s black.<p>" +
      "<p>It’s green.<p>" +
      "<p>It’s blue.<p>" +
      "<p>Lots of colors for me and you.<p>" +
      "<p>It’s red.<p>" +
      "<p>It’s black.<p>" +
      "<p>It’s green.<p>" +
      "<p>It’s blue.<p>" +
      "<p>Lots of colors for me and you.<p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page11/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page11/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page11/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB1-U1-P11-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page11/E3/1.jpg' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB1-U1-P11-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page11/E4/1.jpg' },
      ],
    ]
  },


}

export default json;