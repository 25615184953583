
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'



const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P37-E1',
    audio: 'img/FriendsPlus/Page37/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page37/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/anh1.e1.mp3' },
        { url: 'img/FriendsPlus/Page37/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/anh2.e1.mp3' },
        { url: 'img/FriendsPlus/Page37/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/anh3.e1.mp3' },
        { url: 'img/FriendsPlus/Page37/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/anh4.e1.mp3' },
      ],


    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P37-E2',
    audio: 'img/FriendsPlus/Page37/Audio/sing.e2.mp3',
    video: '',
    songLyric:
      "<p>This is my nose.</p>" +
      "<p>This is my face.</p>" +
      "<p>These are my arms.</p>" +
      "<p>These are my legs.</p>" +
      "<p>These are my fingers.</p>" +
      "<p>These are my hands.</p>" +
      "<p>This is me!</p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page37/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P37-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page37/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/This is my face..mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/This is my nose.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/These are my arms..mp3' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P37-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page37/E4/1.jpg' },
      ],
    ]
  },

}

export default json;
