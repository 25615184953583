
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P25-E1',
    audio: 'img/FriendsPlus/Page25/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page25/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/4.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/7.mp3' },
        { url: 'img/FriendsPlus/Page25/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/8.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P25-E2',
    audio: 'img/FriendsPlus/Page25/Audio/sing.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page25/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page25/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/7.mp3' },
        { url: 'img/FriendsPlus/Page25/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page25/Audio/8.mp3' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P25-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page25/E3/1.jpg' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P25-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page25/E4/1.jpg' },
      ],
    ]
  },
}

export default json;