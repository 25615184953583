import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
    1: { // Exercise num
        unit: 'Culture 6',
        id: 'SB1-C-P57-E1',
        audio: 'img/FriendsPlus/Page57/Audio/audio.e1.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page57/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page57/Audio/tieude.e1.mp3' },
                { url: 'img/FriendsPlus/Page57/E1/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page57/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page57/Audio/sand.mp3' },
                { url: 'img/FriendsPlus/Page57/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page57/Audio/sea.mp3' },
                { url: 'img/FriendsPlus/Page57/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page57/Audio/shell.mp3' },
                { url: 'img/FriendsPlus/Page57/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page57/Audio/sun.mp3' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Culture 6',
        id: 'SB1-C-P57-E2',
        //audio: 'img/FriendsPlus/Page57/Audio/audio.e2.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page57/E2/1.jpg' },
            ],

        ],
    },
    3: { // Exercise num
        unit: 'Culture 6',
        id: 'SB1-C-P57-E3',
        audio: '',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        question: [
        ],
        questionImage: [
            [
                { url: 'img/FriendsPlus/Page57/E3/1.jpg' },
            ],
        ]
    },



}

export default json;