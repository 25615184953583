
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import TypeInNoSubmit from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P7-E1',
    audio: 'img/FriendsPlus/Page7/Audio/audio.e1.mp3',
    video: '',
    component: TypeInNoSubmit,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page7/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page7/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page7/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/4.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page7/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page7/Audio/1.mp3' },
        { url: 'img/FriendsPlus/Page7/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page7/Audio/2.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P7-E2',
    audio: 'img/FriendsPlus/Page7/Audio/sing.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page7/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page7/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page7/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page7/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page7/Audio/1.mp3' },
        { url: 'img/FriendsPlus/Page7/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page7/Audio/2.mp3' },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P7-E3',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 50,
    inputHeight: '100%',
    isAllowSubmit: true,
    // isLargeInput: true,
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page7/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page7/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/3.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page7/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/5.jpg', input: true, answer: "1" },
        { url: 'img/FriendsPlus/Page7/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/7.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page7/E3/8.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P7-E4',
    audio: '',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page7/E4/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page7/E4/1.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page7/E4/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page7/E4/5.jpg', isMatching: true, id: 2 },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/3.jpg' },
        { url: 'img/FriendsPlus/Page7/E4/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E4/4.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page7/E4/7.jpg', isMatching: true, id: 4 },
      ],
    ],
    answers: ['1-4', '2-3'],
  },
  5: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P7-E5',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page7/E5/1.jpg' },
      ],
    ]
  },
}

export default json;